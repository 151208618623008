
import { Component, Vue } from 'vue-property-decorator'
import { CarMaintainInfo } from '@/types/carManage'

@Component({
  name: 'detailMaintain'
})
export default class extends Vue {
  private info: CarMaintainInfo = {
    projectId: '',
    vehicleId: '',
    vehicleType: '',
    repairTime: '',
    repairAddress: '',
    repairReason: '',
    repairPrice: '',
    brand: '',
    model: '',
    vehicleTypeName: ''
  }

  created () {
    const repairId = this.$route.params.id
    repairId && this.getDetail(repairId)
  }

  getDetail (repairId: string) {
    this.$axios.get(this.$apis.carManage.selectYhVehicleRepairByRepairId, { repairId }).then((res) => {
      this.info = res
    })
  }
}
